import logo from "assets/svg/logo.svg";

//LINKS
import { Link } from "react-router-dom";

const Header = () => {
    return (
        <>
            <header>
                <nav className="container">
                    <div className="grid-2 flex-space-between cta-mobile-border-bottom">
                        <div className="logo">
                            <Link to="/">
                                <img src={logo} alt="Birrib Logo"/>
                            </Link>
                        </div>
                        <div className="cta-mobile">
                            <ul className="menu social-media">
                                <li>
                                    <Link to="https://www.linkedin.com/in/joneilsonalves/" target="_blank" className="nav-link">Linkedin</Link>
                                </li>
                                <li>
                                    <Link to="https://www.behance.net/joneilsonalves1" target="_blank" className="nav-link">Behance</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="grid-8">
                        <ul className="menu SMN_effect-25">
                            <li className="">
                                <Link to="/" className="nav-link">WORK</Link>
                            </li>
                            <li>
                                <Link to="/about" className="nav-link">ABOUT</Link>
                            </li>
                            <li>
                                <Link to="/reel"  className="nav-link">MOTION REEL</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="grid-2 cta-desktop">
                        <ul className="menu social-media">
                            <li>
                                <Link to="https://www.linkedin.com/in/joneilsonalves/" target="_blank" className="nav-link">Linkedin</Link>
                            </li>
                            <li>
                                <Link to="https://www.behance.net/joneilsonalves1" target="_blank" className="nav-link">Behance</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
            <div className="bg-noise"></div>
        </>
    );
}

export default Header;