//HEADER & FOOTER
import Header from "page/Header";
import Footer from "page/Footer";

//ASSETS
import btnPlay from "assets/svg/play.svg";
import btnWorld from "assets/svg/world.svg";

//LINKS
import { Link } from "react-router-dom";

const About = () => {
    return (
        <>  
            <Header />

            <section className="hero">
                <div className="container about-container">
                    <div className="grid-6 about">
                        <h1 className="about_title">Who is behind the birrib backstage?</h1>
                        <p className="about_description">
                        Hi! I’m Johnny Oliveira, a Brazilian <b>Motion Designer</b> and <b>Animator</b> based in São Paulo.<br/><br/>
                        <b>Birrib</b> represents my animation work, created to collaborate with people who wish to bring any kind of design to life.<br/><br/>
                        I am currently a <b>full-time Motion Designer</b> and dedicate part of my time to managing Birrib, a small <b>personal brand</b> committed to assisting directors, studios, brands and individuals with theis <b>design and animation</b> needs.<br/>
                        </p>

                        <p className="about_cta">Let’s be in touch!</p>

                        <div className="about_contact-container">
                            <h2 className="about_email">contato@birrib.com.br</h2>
                            <h2 className="about_phone">11 93218-8066</h2>
                        </div>

                    </div>
                    <div className="grid-6 about">
                        <div className="about_perfil">
                            <div className="photo">
                                <img src="https://i.imgur.com/4bIO5On.png" alt="Johnny Oliveira"/>
                            </div>
                            <div className="perfil_info">
                                <div className="play-min">
                                    <span className="play-icon"><img src={btnPlay} alt="Play icon" /></span>
                                    <span className="play-icon world"><img src={btnWorld} alt="Play icon" /></span>
                                </div>
                                <div className="perfil-details">
                                    <h2 className="perfil-title">Johnny Oliveira</h2>
                                    <div className="perfil-links SMN_effect-25">
                                        <Link to="https://www.linkedin.com/in/joneilsonalves/" target="_blank" className="nav-link">Linkedin</Link>
                                        <Link to="https://www.linkedin.com/in/joneilsonalves/" target="_blank" className="nav-link">Instagram</Link>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="about_vitae">
                            <div className="vitae_container">
                                <h4 className="vitae_title">Education</h4>
                                
                                <div className="vitae_section">
                                    <p class="vitae_section--title">Motion Boss // Layer Lemonade</p>
                                    <p class="vitae_section--subtitle">Novembro de 2024 - Presente</p>
                                </div>

                                <div className="vitae_section">
                                    <p class="vitae_section--title">Motion Design Essencial //  Layer Lemonade</p>
                                    <p class="vitae_section--subtitle">Novembro de 2024 - Presente</p>
                                </div>
                                
                                <div className="vitae_section">
                                    <p class="vitae_section--title">Praticas Avançadas de Motion Design //  Layer Lemonade</p>
                                    <p class="vitae_section--subtitle">Novembro de 2024 - Presente</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default About;