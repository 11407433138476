//HEADER & FOOTER
import Header from "page/Header";
import Footer from "page/Footer";


const Reel = () => {
    return (
        <>  
            <Header />
            <section className="hero">
                <div className="container post-reel ">
                    <div className="grid-12">
                        <div className="video-player" >
                            <iframe title="reel" className="video" src="https://player.vimeo.com/video/1050309613?title=0&byline=0&portrait=0" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" data-ready="true"></iframe>
                        </div>                   
                    </div>
                    
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Reel;