import { BrowserRouter as Router, Route, Routes} from "react-router-dom";

//PAGES
import Post from "page/Post";
import About from "page/About";
import Contact from "page/Contact";
import Reel from "page/Reel";
/* import NotFound from "page/NotFound"; */
import Home from "page/Home";

const Paths = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Home/>} />

                    <Route path="/project/:idPost" element={<Post/>} />
                    
                    <Route path="/about" element={<About/>} />
                    <Route path="/contact" element={<Contact/>} />
                    <Route path="/reel" element={<Reel/>} />

                    <Route path="*" element={<Home/>} />
                </Routes>
            </Router>
        </>
    );
}

export default Paths;